<template>
  <div id="login-view">
    <b-overlay>
      <section class="bg-light ftco-section">
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/site/images/loader.gif"
            v-if="loader === true"
            class="img-fluid  loader-width"
            alt
          />
        </div>
        <div class="container" v-if="loader === false">
          <div class="row flex-lg-row flex-md-row">
            <div class="col-md-5">
              <div style="margin-top: 2rem">
                <div class="card-body bg-dark-gray">
                  <h2 class="text-black">Login</h2>
                  <p class="text3" style="font-size: 12px">
                    WELCOME GOVAVA
                  </p>
                  <form>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="email"
                        placeholder="Email Address"
                      />
                      <span class="text-danger" v-if="errors.email">{{
                        this.errors.email[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        v-model="password"
                        placeholder="Password"
                      />
                      <span class="text-danger" v-if="errors.password">{{
                        this.errors.password[0]
                      }}</span>
                    </div>
                    <div class="row ml-2">
                      <vue-recaptcha
                        sitekey="6LdOEbUcAAAAAJD8wvT14kaBQ-RSrgPZsmMXcbMr"
                        :loadRecaptchaScript="true"
                        @verify="onVerify"
                        @error="errorMethod"
                      >
                      </vue-recaptcha>
                    </div>
                    <div class="row ml-2">
                      <span class="text-danger" v-if="captchaError"
                        ><b>Please check the recaptcha box </b></span
                      >
                    </div>
                    <span class="text-danger" v-if="errors.gRecaptcha">{{
                      this.errors.gRecaptcha[0]
                    }}</span>
                    <div class="row">
                      <div class="col-8">
                        <span class="text-danger">{{ error_msg }}</span>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <!-- /.col -->
                      <div class="col-4 mt-4 mb-2">
                        <button
                          @click="login"
                          type="button"
                          class="btn btn-primary btn-block "
                        >
                          Login
                        </button>
                      </div>
                    </div>
                    <!-- <p style="font-size:14px">
                      Don’t have an account?
                      <router-link to="/site/signup"> Sign Up</router-link>
                    </p> -->
                    <!-- <router-link to="/site/forgot" style="font-size:14px">Forgot Password?</router-link> -->
                    <!-- /.col -->
                  </form>
                </div>
                <!-- /.login-card-body -->
              </div>
            </div>
            <div class="col-md-7">
              <img
                src="@/assets/site/images/login-bg.jpg"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </b-overlay>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "LoginView",
  components: { VueRecaptcha },
  data() {
    return {
      email: null,
      password: null,
      errors: [],
      error_msg: null,
      loader: false,
      response: null,
      captchaError: false,
    };
  },
  methods: {
    login() {
      //Save or update User Details
      this.errors = [];
      this.error_msg = null;
      this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "api/login";
      let method_type = "post";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
          gRecaptcha: this.response,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            var userData = {
              user: data.user,
              token: data.token,
              status: true,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            window.location.href = "admin";
          } else if (data.status === false) {
            localStorage.clear();
            this.loader = false;
            this.error_msg = data.message;
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          console.log("User Data", "Error : " + err.message, "error");
        });
    },
    onVerify(response) {
      this.response = response;
      console.log(" captcha response >>>>>  ", response);
    },
    errorMethod(response) {
      console.log(" captcha Error >>>>>  ", response);
    },
  },
};
</script>
